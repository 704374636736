import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

const Withdraw = () => {
  const [user, setUser] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false); // Track if input/button should be disabled
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/profile",
          {
            withCredentials: true,
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();

    // Function to check if the time is between midnight and 8 AM in Bangkok timezone
    const checkTime = () => {
      // Get the current time in Bangkok (UTC+7)
      const now = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Bangkok",
      });
      const currentTime = new Date(now);
      const hours = currentTime.getHours();

      // Check if the current time is between midnight (0:00) and 8:00 AM
      if (hours >= 0 && hours < 8) {
        setIsDisabled(true); // Disable input and button between midnight and 8 AM
      } else {
        setIsDisabled(false); // Enable input and button after 8 AM
      }
    };

    checkTime(); // Run once on initial load
    const interval = setInterval(checkTime, 60000); // Check every minute

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  const handleChange = (e) => {
    const amount = parseFloat(e.target.value);
    const limit = parseFloat(user?.wallet?.limit?.$numberDecimal || 0); // Parse Decimal128 to float
    const turnover = parseFloat(user?.wallet?.turnOver?.$numberDecimal || 0); // Parse Decimal128 to float
    console.log("limit", limit);

    if (turnover > limit) {
      setWithdrawAmount(amount);
      setErrorMessage("");
    } else {
      setErrorMessage("ต้องทำเทิร์นโอเวอร์ อีก " + (limit - turnover) + " บาท");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (withdrawAmount < 100) {
      setErrorMessage("ถอนขั้นต่ำ 100 บาท");
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/requestwithdraw",
        { amount: withdrawAmount },
        { withCredentials: true }
      );

      const { id } = response.data;
      navigate(`/paymentstatus`);
    } catch (error) {
      console.error("Error submitting withdraw request:", error);
      alert("Failed to submit withdraw request. Please try again.");
    }
  };

  return (
    <div className="h-screen flex justify-center items-center relative">
      <div className="absolute inset-0 blur-xl z-0 bg-login"></div>

      <div className="h-full w-full md:w-[550px] flex flex-col z-10 bg-login">
        <header className="bg-pink-700 text-white py-4 shadow-lg">
          <div className="container mx-auto px-4 flex justify-between items-center">
            <h1 className="text-xl font-bold">Realslot456</h1>
            <div className="flex items-center">
              <button className="bg-green-500 hover:bg-green-400 text-white py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded text-sm">
                เครดิต : {user?.wallet?.balance?.$numberDecimal || 0} บาท
              </button>
            </div>
          </div>
        </header>

        <div className="flex items-center justify-center w-full flex-grow">
          <div className="bg-white shadow-lg w-full text-center mx-2 rounded-xl">
            <Marquee speed={20} className="bg-pink-700 py-2 text-white">
              ------ปิดการถอนตั้งแต่เที่ยงคืนถึง 8 โมงเช้า
              ลูกค้าสามารถทำการถอนได้ตั้งแต่ 8 โมงเช้าเป็นต้นไป ------
            </Marquee>
            <div className="flex items-center justify-center opacity-90 border-4 mb-10">
              <div className="grid grid-cols-1 md:grid-cols-1 p-4 w-full">
                <h1 className="text-2xl font-bold text-black">ถอนเงิน</h1>
                <span>ระบุจำนวนเงินที่ต้องการถอน</span>
                <form onSubmit={handleSubmit}>
                  <input
                    type="number"
                    className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-600 bg-gray-300 mt-6 text-black"
                    placeholder="100.00"
                    onChange={handleChange}
                    value={withdrawAmount}
                    disabled={isDisabled} // Disable input during restricted hours
                  />
                  {errorMessage && (
                    <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
                  )}
                  <button
                    type="submit"
                    className="w-full bg-green-500 text-white mt-6 mb-6 py-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600"
                    disabled={isDisabled || !!errorMessage} // Disable button during restricted hours or if there's an error
                  >
                    ถอนเงิน
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
