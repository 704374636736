import React, { useState, useEffect } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

const GameSlide = ({ banner }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: true, // Enable looping
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel); // Update the currentSlide based on the slider’s relative position
    },
    created() {
      setLoaded(true);
    },
  });

  // Reinitialize slider when banner changes or the component is refreshed
  useEffect(() => {
    if (instanceRef.current) {
      instanceRef.current.update({
        slides: { perView: 1 }, // Ensure slides per view is configured if needed
        loop: true, // Ensure looping is re-enabled on update
      });
    }
  }, [banner]);

  // Autoplay logic with looping enabled
  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      if (instanceRef.current) {
        instanceRef.current.next(); // Automatically move to the next slide
      }
    }, 3000); // Change slides every 3 seconds

    // Clear the interval on component unmount or when instance changes
    return () => clearInterval(autoplayInterval);
  }, [instanceRef]);

  return (
    <div className="flex items-center justify-center w-full mt-1">
      <div className="bg-gray-200 opacity-90 shadow-lg w-full text-center mx-2 rounded-t-xl">
        <div className="flex-grow flex items-center justify-center opacity-90">
          <div className="grid grid-cols-1 md:grid-cols-1 p-1">
            <div className="flex-row">
              <div className="navigation-wrapper">
                <div ref={sliderRef} className="keen-slider">
                  {banner && banner.length > 0 ? (
                    banner.map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        alt={`Slide ${index + 1}`}
                        className={`text-white rounded-lg w-full keen-slider__slide number-slide${
                          index + 1
                        }`}
                        loading="lazy" // Lazy load images
                      />
                    ))
                  ) : (
                    <p className="text-gray-500">No images available</p>
                  )}
                </div>
                {loaded && instanceRef.current && (
                  <>
                    <Arrow
                      left
                      onClick={(e) =>
                        e.stopPropagation() || instanceRef.current?.prev()
                      }
                    />
                    <Arrow
                      onClick={(e) =>
                        e.stopPropagation() || instanceRef.current?.next()
                      }
                    />
                  </>
                )}
              </div>
              {/* {loaded && instanceRef.current && (
                <div className="dots">
                  {Array.from({
                    length: instanceRef.current.track.details.slides.length,
                  }).map((_, idx) => (
                    <button
                      key={idx}
                      onClick={() => instanceRef.current?.moveToIdx(idx)}
                      className={
                        "dot" + (currentSlide === idx ? " active" : "")
                      }
                    ></button>
                  ))}
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameSlide;
