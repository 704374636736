// src/hooks/useFetchGames.js
import { useState, useCallback } from "react";
import axios from "axios";

export const useFetchGames = () => {
  const [allGames, setAllGames] = useState([]);

  const fetchAllGames = useCallback(async () => {
    try {
      const config = {
        method: "get",
        url: "https://s.ss365api.com/gs/api/platform/games/list",
        params: {
          casinoId: "14000039",
          includeDisabled: true,
          bonusProgress: true,
        },
        headers: {
          "hg-agent-user": "realslot456",
          "hg-agent-password": "PvuPhGIetHKZNsVaM1m8",
        },
      };
      const response = await axios.request(config);
      setAllGames(response.data.response);
    } catch (error) {
      console.error("Error fetching all games:", error);
    }
  }, []);

  return { allGames, fetchAllGames };
};
