// src/components/Game/GameSection.js
//import React from "react";
import { useNavigate } from "react-router-dom";

const GameMainBut = ({ selectedGame, setSelectedGame }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      {/* Section Header */}
      {/* <div className="flex items-center justify-center w-full">
        <div className="bg-pink-700 shadow-xl w-full text-center mx-2 mt-5 rounded-t-xl">
          <div className="flex-grow flex opacity-90 border-4mb-10 p-2 text-white">
            หมวดหมู่
          </div>
        </div>
      </div> */}

      {/* Section Content */}
      <div className="flex items-center justify-center w-full">
        <div className="bg-gray-200 opacity-90 shadow-lg w-full text-center mx-2 pt-1">
          <div className="grid grid-cols-2 md:grid-cols-2 pb-2 pt-0 w-full gap-1">
            <button
              className="bg-green-500 hover:bg-green-400 text-white p-2 border-b-4 border-green-700 hover:border-green-500 rounded text-sm ml-1"
              onClick={() => handleNavigation("/deposit")}
            >
              เติมเงิน
            </button>
            <button
              className="bg-red-500 hover:bg-red-400 text-white p-2 border-b-4 border-red-700 hover:border-red-500 rounded text-sm mr-1"
              onClick={() => handleNavigation("/withdraw")}
            >
              ถอนเงิน
            </button>
            {/* <div
              className=" text-white font-bold py-2 px-4 border-4 border-pink-700 rounded-xl mx-1"
              onClick={handleclick("lotto")}
            >
              <img src={lottosvg} alt="React Logo" />
              <p className="text-black text-xs mt-2">หวย</p>
            </div>
            <div
              className=" text-white font-bold py-2 px-4 border-4 border-pink-700 rounded-xl mx-1"
              onClick={handleclick("slot")}
            >
              <img src={slotsvg} className="w-full p-2" alt="React Logo" />
              <p className="text-black text-xs mt-2">สล๊อต</p>
            </div>
            <div
              className=" text-white font-bold py-2 px-4 border-4 border-pink-700 rounded-xl mx-1 "
              onClick={handleclick("casino")}
            >
              <img src={testsvg} className="p-1" alt="React Logo" />
              <p className="text-black text-xs mt-2">คาสิโน</p>
            </div> */}

            {/* <div
              className=" text-white font-bold py-2 px-4 border-4 border-pink-700 rounded-xl mx-1"
              onClick={handleclick("sport")}
            >
              <img src={sportsvg} className="p-1" alt="React Logo" />
              <p className="text-black text-xs mt-2">กีฬา</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameMainBut;
