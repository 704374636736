// src/hooks/useFetchUser.js
import { useState, useCallback } from "react";
import axios from "axios";

export const useFetchUser = () => {
  const [user, setUser] = useState(null);
  const [playerId, setPlayerId] = useState(null);

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/profile`,
        { withCredentials: true }
      );
      setPlayerId(response.data._id);
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }, []);

  return { user, playerId, fetchUserDetails };
};
