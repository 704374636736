// src/components/Game/GameSection.js
import React from "react";

const GameText = () => {
  return (
    <>
      {/* <div className="flex items-center justify-center w-full mt-1">
        <div className="bg-pink-700 shadow-xl w-full text-center mx-2  rounded-t-xl">
          <div className="text-sm flex-grow flex opacity-90 border-4mb-10 p-1 text-white justify-center">
            เกมส์ยอดนิยม
          </div>
        </div>
      </div> */}
      <div className="flex items-center justify-center w-full mt-1">
        <div className="bg-gray-200 opacity-90 shadow-lg w-full text-center mx-2 rounded-xl ">
          <div className="flex-grow flex items-center justify-center border-4mb-10">
            {/* <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md text-center"> */}
            <div className="flex overflow-hidden border-pink-700 border-1 rounded-xl">
              <div className="flex w-full">
                <div className="text-gray-700 p-4 text-sm">
                  <p className="text-black pb-2 underline">REALSLOT456</p>
                  <span>
                    เว็บตรงที่ควบคุมดูแลมาตรฐาน สล็อตเว็บตรง
                    อย่างมั่นคงที่สุดในตอนนี้ 2024
                    ได้รับความนิยมอย่างมากในปัจจุบัน สล็อตเว็บตรง รวม สล็อต
                    จากทุกค่ายชั้นนำ ที่มีการจ่ายเงินรางวัลให้กับทุกท่าน
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameText;
