import React, { useState, useEffect } from "react";
import axios from "axios";

const UserDetailPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [creditAmount, setCreditAmount] = useState("");
  const [message, setMessage] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isModalOpenBonus, setIsModalOpenBonus] = useState(false);

  // Fetch users data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/users`
        );
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to load user data");
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);

  // Fetch transactions data
  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/payment`
        );
        setTransactions(response.data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
    fetchTransactionData();
  }, []);

  const filteredUsers = users.filter(
    (user) => user.bank?.telephone && user.bank.telephone.includes(searchTerm)
  );

  const openModal = (userId) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };
  const openModalBonus = (userId) => {
    setSelectedUserId(userId);
    setIsModalOpenBonus(true);
  };

  const handleAddCredit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/addbalanceadmin/${selectedUserId}`,
        { amount: creditAmount }
      );

      if (response.status === 200) {
        setMessage("Credit added successfully.");
        setIsModalOpen(false); // Close the modal
        setCreditAmount(""); // Clear the input

        // Refresh the page
        window.location.reload();
      }
    } catch (error) {
      console.error("Error adding credit:", error);
      setMessage("Failed to add credit. Please try again.");
    }
  };

  const handleAddBonus = async (e) => {
    e.preventDefault();
    const bonusAmount = 50; // You can adjust this value

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/addbonusadmin/${selectedUserId}`,
        { amount: bonusAmount }
      );

      if (response.status === 200) {
        setMessage("Bonus added successfully.");
        setIsModalOpenBonus(false); // Close the modal
        setCreditAmount(""); // Clear the input

        // Refresh the page
        window.location.reload();
      }
    } catch (error) {
      console.error("Error adding bonus:", error);
      setMessage("Failed to add bonus. Please try again.");
    }
  };

  const renderTransactionRow = (transaction) => {
    const statusColor =
      transaction.status === "SUCCESS"
        ? "text-green-600"
        : transaction.status === "PENDING"
        ? "text-yellow-600"
        : "text-red-600";

    return (
      <tr key={transaction._id.$oid} className={`border-t ${statusColor}`}>
        <td className="px-4 py-2">
          {parseFloat(transaction.amount.$numberDecimal.toString())}
        </td>
        {/* <td className="px-4 py-2">{transaction.fromBank}</td>
        <td className="px-4 py-2">{transaction.fromAccount}</td> */}
        <td className="px-4 py-2">{transaction.createdAt}</td>
        <td className="px-4 py-2 font-semibold">{transaction.status}</td>
      </tr>
    );
  };

  if (loading) {
    return <p className="text-center">Loading user data...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="max-w-5xl mx-auto p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">User Details</h2>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by telephone"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {message && <p className="text-center text-green-500">{message}</p>}

      {filteredUsers.map((user) => {
        const userTransactions = transactions.filter(
          (txn) => txn.userId === user._id
        );
        const deposits = userTransactions.filter(
          (txn) => txn.type === "DEPOSIT"
        );
        const withdrawals = userTransactions.filter(
          (txn) => txn.type === "WITHDRAWAL"
        );
        console.log("userTransactions", userTransactions);
        console.log("withdrawals", withdrawals);

        return (
          <div
            key={user._id.$oid}
            className="mb-8 border rounded-lg p-4 shadow"
          >
            <div className="flex items-center mb-4">
              <img
                src={user.pictureUrl}
                alt={`${user.displayName}'s profile`}
                className="w-20 h-20 rounded-full mr-4"
              />
              <div>
                <p className="text-xl font-semibold">{user.displayName}</p>
                <p className="text-gray-600">Role: {user.role}</p>
              </div>
            </div>

            <table className="min-w-full bg-white border border-gray-200">
              <tbody>
                <tr className="border-t">
                  <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                    Wallet Balance
                  </th>
                  <td className="px-4 py-2">
                    {user.wallet?.balance
                      ? parseFloat(user.wallet.balance.$numberDecimal).toFixed(
                          2
                        )
                      : "N/A"}
                    <button
                      className="bg-gray-700 text-white ml-4 px-4 py-2 rounded"
                      onClick={() => openModal(user._id.toString())}
                    >
                      Add Credit
                    </button>
                    <button
                      className="bg-gray-700 text-white ml-4 px-4 py-2 rounded"
                      onClick={() => openModalBonus(user._id.toString())}
                    >
                      Add Bonus
                    </button>
                  </td>
                </tr>
                <tr className="border-t">
                  <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                    Turnover
                  </th>
                  <td className="px-4 py-2">
                    {user.wallet?.turnOver
                      ? parseFloat(user.wallet.turnOver.$numberDecimal).toFixed(
                          2
                        )
                      : "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="min-w-full bg-white border border-gray-200">
              <tbody>
                <tr className="border-t">
                  <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                    bank
                  </th>
                  <td className="px-4 py-2">
                    {user.bank?.bankName ? user.bank.bankName : "N/A"}
                  </td>
                </tr>
                <tr className="border-t">
                  <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                    AccountNumber
                  </th>
                  <td className="px-4 py-2">
                    {user.bank?.accountNumber ? user.bank.accountNumber : "N/A"}
                  </td>
                </tr>
                <tr className="border-t">
                  <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                    accountName
                  </th>
                  <td className="px-4 py-2">
                    {user.bank?.accountName ? user.bank.accountName : "N/A"}
                  </td>
                </tr>
                <tr className="border-t">
                  <th className="px-4 py-2 text-left font-semibold text-gray-700 bg-gray-100 w-1/3">
                    telephone
                  </th>
                  <td className="px-4 py-2">
                    {user.bank?.telephone ? user.bank.telephone : "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Deposit Transactions */}
            <h3 className="text-lg font-semibold mt-6 mb-2 text-green-600">
              Deposits
            </h3>
            <table className="min-w-full bg-white border border-gray-200 mb-4">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2">Amount</th>
                  <th className="px-4 py-2">Date</th>
                  <th className="px-4 py-2">Status</th>
                </tr>
              </thead>
              <tbody>{deposits.map(renderTransactionRow)}</tbody>
            </table>

            {/* Withdraw Transactions */}
            <h3 className="text-lg font-semibold mt-6 mb-2 text-red-600">
              Withdrawals
            </h3>
            <table className="min-w-full bg-white border border-gray-200 mb-4">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2">Amount</th>
                  <th className="px-4 py-2">Date</th>
                  <th className="px-4 py-2">Status</th>
                </tr>
              </thead>
              <tbody>{withdrawals.map(renderTransactionRow)}</tbody>
            </table>
          </div>
        );
      })}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-semibold mb-4">Add Credit</h2>
            <form onSubmit={handleAddCredit}>
              <label className="block mb-2 text-gray-700">
                Amount:
                <input
                  type="number"
                  value={creditAmount}
                  onChange={(e) => setCreditAmount(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg mt-1 focus:outline-none"
                  required
                />
              </label>
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  className="bg-gray-400 text-white px-4 py-2 rounded mr-2"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {isModalOpenBonus && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-semibold mb-4">Add Credit</h2>
            <form onSubmit={handleAddBonus}>
              <label className="block mb-2 text-gray-700">
                Amount:
                <input
                  type="number"
                  value={50}
                  onChange={(e) => setCreditAmount(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg mt-1 focus:outline-none"
                  required
                />
              </label>
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  className="bg-gray-400 text-white px-4 py-2 rounded mr-2"
                  onClick={() => setIsModalOpenBonus(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {filteredUsers.length === 0 && (
        <p className="text-center text-gray-600">
          No users found for this telephone number.
        </p>
      )}
    </div>
  );
};

export default UserDetailPage;
