import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import axios from "axios";

const ProtectedRoute = ({ requiredRole }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/auto-login",
          {
            withCredentials: true,
          }
        );
        console.log("User data:", response.data); // Debug log
        setIsAuthenticated(true);
        setUser(response.data);
      } catch (error) {
        console.log("Authentication failed"); // Debug log
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/register" />;
  }

  // Redirect to registration if bank account is missing
  if (user.bank.accountNumber === null) {
    return <Navigate to="/register" />;
  }

  console.log("User:", user); // Debug log
  console.log("User role:", user?.role); // Debug log

  // Check if user has the required role or is an admin
  if (requiredRole && user?.role !== requiredRole && user?.role !== "admin") {
    return <Navigate to="/not-authorized" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
