// src/components/Game/Game.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import GameHeader from "./GameHeader.js";
import GameSection from "./GameSection.js";
import GameFooter from "./GameFooter";
import { useFetchUser } from "../../hooks/useFetchUser";
import { useFetchGames } from "../../hooks/useFetchGames";
import "../styles.css";
import GameButton from "./GameButton.js";
import Casino from "./Casino.js";
import Huay from "./Huay.js";
import Sport from "./Sport.js";
import GameSlide from "./GameSlide.js";
import Marquee from "react-fast-marquee";
import GameIconMarquee from "./GameIconMarquee.js";
import CasinoBanner from "./CasinoBanner.js";
import GameText from "./GameText.js";
import GameMainBut from "./GameMainBut.js";

const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileDevices =
      /(android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile)/i;

    setIsMobile(mobileDevices.test(userAgent));
  }, []);

  return isMobile;
};

const Game = () => {
  const isMobile = useDeviceDetect();
  const { user, playerId, fetchUserDetails } = useFetchUser();
  const { allGames, fetchAllGames } = useFetchGames();
  const [selectedGame, setSelectedGame] = useState("");
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    fetchUserDetails();
    fetchAllGames();

    const handlePopState = () => {
      fetchUserDetails();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [fetchUserDetails, fetchAllGames]);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY + 5) {
        setShowFooter(false);
      } else if (currentScrollY < lastScrollY - 5) {
        setShowFooter(true);
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const launchGame = async (gameId) => {
    const params = {
      playerId,
      gameId,
      casinoId: process.env.REACT_APP_GAMEAPICASINOID,
    };
    try {
      console.log("Request params:", params);
      const response = await axios.post(
        process.env.REACT_APP_GAMEAPIURL,
        null,
        {
          params,
          headers: {
            "hg-agent-user": process.env.REACT_APP_GAMEAPIUSER,
            "hg-agent-password": process.env.REACT_APP_GAMEAPIPASSWORD,
          },
        }
      );

      console.log("API response:", response.data);
      const gameUrl = response.data.response.gameUrl;
      if (gameUrl) {
        window.location.href = gameUrl; // Redirect to the game URL for other vendors
      } else {
        console.error("gameUrl not found in response for vendor:", gameId);
      }
    } catch (error) {
      console.error("Error fetching game URL for vendor:", gameId, error);
    }
  };

  const LaunchHuay = async () => {
    const huaydragonParams = { playerId };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/huaydragon/launch",
        huaydragonParams,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log("API response:", response.data);
      const gameUrl = response.data.data.url;
      if (gameUrl) {
        window.location.href = gameUrl; // Redirect directly to the game URL for huaydragon
        return; // Ensure no further code execution
      } else {
        console.error("gameUrl not found in response for huaydragon");
      }
    } catch (error) {
      console.error("Error fetching game URL for huaydragon:", error);
    }
    return; // Return early after handling huaydragon
  };

  const LaunchCasino = async (gameId) => {
    const hasPromotion =
      user?.promotion && user.promotion.$numberDecimal.toString() == "2.00";
    console.log("hasPromotion:", user.promotion.$numberDecimal.toString());
    if (hasPromotion) {
      alert("รับโปรโมชั่น ไม่สามารถเข้าเล่น casino ได้");
      return;
    }

    const casinoParams = { playerId: user._id, gameId, isMobile };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/ifuse/launch`,
        casinoParams,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      console.log("API response:", response.data);

      const gameUrl = response.data.data;
      if (gameUrl) {
        window.location.href = gameUrl;
        return;
      } else {
        console.error("gameUrl not found in response for huaydragon");
      }
    } catch (error) {
      console.error("Error fetching game URL for huaydragon:", error);
    }
  };

  const gameCategories = {
    pgsoft: allGames.filter((game) => game.brand === "pgsoft"),
    eagm: allGames.filter((game) => game.brand === "eagm"),
    gwa: allGames.filter((game) => game.brand === "gwa"),
    pragmatic: allGames.filter((game) => game.brand === "pragmatic"),
    bingo: allGames.filter((game) => game.brand === "bingo"),
  };
  const banner = [
    `${process.env.PUBLIC_URL}/pgsoftgames/banner1.webp`,
    `${process.env.PUBLIC_URL}/pgsoftgames/banner2.webp`,
    `${process.env.PUBLIC_URL}/pgsoftgames/banner3.webp`,
  ];

  return (
    <div className="min-h-screen flex justify-center items-center relative">
      {/* Background Blur */}
      <div className="absolute inset-0 blur-xl z-0 bg-login"></div>

      {/* Content without Blur */}
      <div className="min-h-screen md:w-[550px] sm:w-full flex flex-col z-10 bg-login pb-14">
        <GameHeader user={user} />
        <div className="text-black text-sm bg-white p-1 opacity-60">
          <Marquee speed="20">
            เปิดรับทุกเลขไม่มีอั้น ราคาสูงสุดในตลาด จ่าย 100/1000 หวย เกมส์
            สล็อต ออนไลน์ ที่นี่ที่เดียว
          </Marquee>
        </div>
        <GameSlide banner={banner} />
        <GameMainBut />

        <GameButton
          selectedGame={selectedGame}
          setSelectedGame={setSelectedGame}
        />
        {!selectedGame && <GameText />}
        {!selectedGame && <GameIconMarquee />}
        {!selectedGame && <CasinoBanner onLaunchCasino={LaunchCasino} />}
        {!selectedGame && <Huay onLaunchHuay={LaunchHuay} />}
        {!selectedGame && <Sport />}

        {selectedGame === "slot" &&
          Object.entries(gameCategories).map(([category, games]) => (
            <GameSection
              key={category}
              title={`${
                category.charAt(0).toUpperCase() + category.slice(1)
              } Games`}
              games={games}
              onLaunchGame={launchGame}
            />
          ))}
        {selectedGame === "casino" && <Casino onLaunchCasino={LaunchCasino} />}
        {selectedGame === "lotto" && <Huay onLaunchHuay={LaunchHuay} />}
        {selectedGame === "sport" && <Sport />}
        <GameFooter />

        {/* Use Tailwind classes for transitions */}
        {/* <div
          className={`transition-opacity duration-500 ${
            showFooter ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <GameFooter />
        </div> */}
      </div>
    </div>
  );
};

export default Game;
