import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminWithdrawTable = () => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [transferStatus, setTransferStatus] = useState(""); // For feedback on transfer status

  // Fetch withdrawal data
  useEffect(() => {
    const fetchWithdrawData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/adminwithdraw`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching withdrawal data", error);
      }
    };

    // Initial fetch
    fetchWithdrawData();

    // Set interval to fetch data every 5 minutes (300000 ms)
    const interval = setInterval(fetchWithdrawData, 300000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, []);

  // Fetch user data
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/users`
        );
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users", error);
        setLoading(false);
      }
    };

    // Initial fetch
    fetchUsers();

    // Set interval to fetch data every 5 minutes (300000 ms)
    const interval = setInterval(fetchUsers, 300000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, []);

  // Helper function to format Decimal128
  const formatAmount = (amount) => {
    if (amount && amount.$numberDecimal) {
      return parseFloat(amount.$numberDecimal).toFixed(2); // Format to 2 decimal places
    }
    return "0.00";
  };

  // Function to format date to UTC+7
  const formatDateToUTC7 = (dateString) => {
    const options = {
      timeZone: "Asia/Bangkok", // UTC+7 timezone
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formatter = new Intl.DateTimeFormat("en-GB", options);
    const formattedDate = formatter.format(new Date(dateString));
    return formattedDate;
  };

  // Function to get the bank details of a user
  const getUserBankDetails = (userId) => {
    const user = users.find(
      (user) => user._id.toString() === userId.toString()
    );
    if (user && user.bank) {
      return (
        <div>
          <p>
            <strong>Bank Name:</strong> {user.bank.bankName}
          </p>
          <p>
            <strong>Account Number:</strong> {user.bank.accountNumber}
          </p>
          <p>
            <strong>Account Name:</strong> {user.bank.accountName}
          </p>
        </div>
      );
    }
    if (user && user.wallet) {
      return (
        <div>
          <p>
            <strong>balance:</strong> {user.wallet.turnOver}
          </p>
          <p>
            <strong>turnOver:</strong> {user.wallet.turnOver}
          </p>
          <p>
            <strong>limit:</strong> {user.wallet.limit}
          </p>
        </div>
      );
    }
    return <p>User not found or no bank details available.</p>;
  };

  // Function to handle the transfer
  const handleTransfer = async (transferId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/approvetransfer`,
        { transferId }
      );
      setTransferStatus("Transfer Approved!"); // Show success message
      // Optionally, you could refetch the withdrawal data to reflect the change
    } catch (error) {
      console.error("Error approving transfer", error);
      setTransferStatus("Error approving transfer. Please try again.");
    }
  };

  return (
    <div className="overflow-x-auto">
      {/* Feedback message */}
      {transferStatus && <p className="text-center py-2">{transferStatus}</p>}

      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2 text-left">Date</th>
            <th className="px-4 py-2 text-left">Amount</th>
            <th className="px-4 py-2 text-left">Bank Details</th>
            <th className="px-4 py-2 text-left">Transfer</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5" className="text-center py-4">
                Loading...
              </td>
            </tr>
          ) : (
            data.map((item) => {
              const userBankDetails = getUserBankDetails(item.userId);
              return (
                <tr key={item._id}>
                  <td className="border px-4 py-2">
                    {formatDateToUTC7(item.createdAt)}
                  </td>
                  <td className="border px-4 py-2">
                    {formatAmount(item.amount)}
                  </td>

                  <td className="border px-4 py-2">{userBankDetails}</td>
                  <td className="border px-4 py-2">
                    <button
                      className="bg-white text-black w-full py-2 px-4 hover:bg-gray-200"
                      onClick={() => handleTransfer(item._id)} // Pass the transferId
                    >
                      Transfer
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminWithdrawTable;
