import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [user, setUser] = useState(null);
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [telephone, setTelephone] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/profile",
          { withCredentials: true }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);
  const thaiBanks = [
    { name: "ธนาคารกรุงเทพ", code: "BBL", logo: "/logos/th/bbl.svg" },
    { name: "ธนาคารกสิกรไทย", code: "KBANK", logo: "/logos/th/kbank.svg" },
    { name: "ธนาคารกรุงไทย", code: "KTB", logo: "/logos/th/ktb.svg" },
    { name: "ธนาคารเจพีมอร์แกน เชส", code: "JPMC", logo: "/logos/th/jpm.svg" },
    {
      name: "ธนาคารทหารไทยธนชาต (TTB)",
      code: "TTB",
      logo: "/logos/th/ttb.svg",
    },
    { name: "ธนาคารไทยพาณิชย์", code: "SCB", logo: "/logos/th/scb.svg" },
    { name: "ธนาคารซิตี้แบงก์", code: "CITI", logo: "/logos/th/citi.svg" },
    { name: "ธนาคารทิสโก้", code: "TISCO", logo: "/logos/th/tisco.svg" },
    { name: "ธนาคารยูโอบี", code: "UOBT", logo: "/logos/th/uob.svg" },
    { name: "ธนาคารกรุงศรีอยุธยา", code: "BAY", logo: "/logos/th/bay.svg" },
    { name: "ธนาคารออมสิน", code: "GSB", logo: "/logos/th/gsb.svg" },
    { name: "ธนาคารเกียรตินาคินภัทร", code: "KKP", logo: "/logos/th/kk.svg" },
    { name: "ธนาคารซีไอเอ็มบี ไทย", code: "CIMBT", logo: "/logos/th/cimb.svg" },
    { name: "ธนาคารไอซีบีซี (ไทย)", code: "ICBCT", logo: "/logos/th/icbc.svg" },
    {
      name: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
      code: "LHFG",
      logo: "/logos/th/lhb.svg",
    },
    { name: "ธนาคารซูมิโตโม มิตซุย", code: "SMTB", logo: "/logos/th/smbc.svg" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "/api/register-bank",
        {
          bankName,
          accountNumber,
          accountName,
          telephone,
        },
        { withCredentials: true }
      );
      navigate("/game");
    } catch (error) {
      console.error("Error registering bank account", error);
    }
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const selectBank = (code) => {
    setBankName(code);
    setDropdownOpen(false);
  };

  return (
    <div className="h-screen flex justify-center items-center relative">
      {/* Background Blur */}
      <div className="absolute inset-0 blur-xl z-0 bg-login"></div>

      {/* Content without Blur */}
      <div className="h-full w-full md:w-[550px] flex flex-col z-10 bg-login">
        <header className="bg-pink-700 text-white py-4 shadow-lg">
          <div className="container mx-auto px-4 flex justify-between items-center">
            <h1 className="text-xl font-bold">Realslot456</h1>
            <div className="flex items-center">
              <button className="bg-green-500 hover:bg-green-400 text-white py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded text-sm">
                เครดิต : {user?.wallet?.balance?.$numberDecimal || 0} บาท
              </button>
            </div>
          </div>
        </header>
        <div className="flex items-center justify-center w-full flex-grow">
          <div className="bg-white shadow-lg w-full text-center mx-2 rounded-xl">
            <div className="flex items-center justify-center opacity-90 border-4mb-10">
              <div className="grid grid-cols-1 md:grid-cols-1 p-4 w-full">
                <h1 className="text-l text-black">
                  เริ่มเล่นเกมส์ในไม่กี่ขั้นตอน
                </h1>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4 relative m-4 ">
                    <label className="block text-gray-700 text-sm font-bold mb-2 text-start bg-gr">
                      ชื่อธนาคาร
                    </label>
                    <div
                      className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 leading-tight focus:bg-gray-700 cursor-pointer"
                      onClick={toggleDropdown}
                    >
                      {bankName
                        ? thaiBanks.find((bank) => bank.code === bankName).name
                        : "กรุณาเลือกธนาคาร"}
                    </div>
                    {dropdownOpen && (
                      <ul className="absolute z-10 w-full bg-gray-700 border rounded shadow-lg mt-2">
                        {thaiBanks.map((bank) => (
                          <li
                            key={bank.code}
                            className="flex items-center p-2 cursor-pointer hover:bg-gray-100 bg-gray-700"
                            onClick={() => selectBank(bank.code)}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}${bank.logo}`}
                              alt={bank.name}
                              className="w-6 h-6 mr-2"
                            />
                            {bank.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="mb-4 m-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 text-start">
                      หมายเลขบัญชี
                    </label>
                    <input
                      type="text"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-6 m-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 text-start">
                      ชื่อบัญชี
                    </label>
                    <input
                      type="text"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-6 m-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 text-start">
                      เบอร์โทรศัพท์
                    </label>
                    <input
                      type="text"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                      required
                    />
                  </div>
                  <div className="flex items-center justify-center  m-4">
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                    >
                      ส่งข้อมูล
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
