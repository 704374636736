// src/components/Game/UserBalance.js
import React from "react";

const UserBalance = ({ balance }) => {
  return (
    <button className="bg-green-500 hover:bg-green-400 text-white p-2 border-b-4 border-green-700 hover:border-green-500 rounded text-sm">
      เครดิต : {balance || 0} บาท
    </button>
  );
};

export default UserBalance;
