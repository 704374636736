// src/components/Game/GameGrid.js
import React from "react";
import GameCard from "./GameCard";

const GameGrid = ({ games, onLaunchGame }) => {
  return (
    <div className="grid grid-cols-4 md:grid-cols-4 gap-1">
      {games?.map((game) => (
        <GameCard key={game.id} game={game} onLaunchGame={onLaunchGame} />
      ))}
    </div>
  );
};

export default GameGrid;
