// src/components/Game/GameSection.js
//import React from "react";
import testsvg from "../../testsvg.svg";
import lottosvg from "../../lotto.svg";
import sportsvg from "../../sport.svg";
import slotsvg from "../../slot.svg";
const GameButton = ({ selectedGame, setSelectedGame }) => {
  const handleclick = (game) => () => {
    setSelectedGame(game);
  };

  return (
    <div>
      {/* Section Header */}
      {/* <div className="flex items-center justify-center w-full">
        <div className="bg-pink-700 shadow-xl w-full text-center mx-2 mt-5 rounded-t-xl">
          <div className="flex-grow flex opacity-90 border-4mb-10 p-2 text-white">
            หมวดหมู่
          </div>
        </div>
      </div> */}

      {/* Section Content */}
      <div className="flex items-center justify-center w-full">
        <div className="bg-gray-200 opacity-90 shadow-lg w-full text-center mx-2 rounded-b-xl">
          <div className="grid grid-cols-4 md:grid-cols-4 pb-4 pt-0 w-full">
            <div
              className=" text-white font-bold py-2 px-4 border-4 border-pink-700 rounded-xl mx-1"
              onClick={handleclick("lotto")}
            >
              <img src={lottosvg} alt="React Logo" />
              <p className="text-black text-xs mt-2">หวย</p>
            </div>
            <div
              className=" text-white font-bold py-2 px-4 border-4 border-pink-700 rounded-xl mx-1"
              onClick={handleclick("slot")}
            >
              <img src={slotsvg} className="w-full p-2" alt="React Logo" />
              <p className="text-black text-xs mt-2">สล๊อต</p>
            </div>
            <div
              className=" text-white font-bold py-2 px-4 border-4 border-pink-700 rounded-xl mx-1 "
              onClick={handleclick("casino")}
            >
              <img src={testsvg} className="p-1" alt="React Logo" />
              <p className="text-black text-xs mt-2">คาสิโน</p>
            </div>

            <div
              className=" text-white font-bold py-2 px-4 border-4 border-pink-700 rounded-xl mx-1"
              onClick={handleclick("sport")}
            >
              <img src={sportsvg} className="p-1" alt="React Logo" />
              <p className="text-black text-xs mt-2">กีฬา</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameButton;
