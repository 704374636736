// src/components/Game/GameSection.js
import React from "react";

const CasinoBanner = ({ onLaunchCasino }) => {
  return (
    <>
      <div className="flex items-center justify-center mt-1 basis-3/5">
        <div className="bg-pink-700 shadow-xl w-full text-center mx-2 rounded-t-xl">
          <div className="text-sm flex-grow flex opacity-90 border-4mb-10 p-1 text-white justify-center">
            คาสิโนยอดนิยม
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full ">
        <div className="bg-white shadow-lg w-full text-center mx-2 opacity-90 rounded-b-xl ">
          <div className="flex-grow flex items-center justify-center border-4mb-10">
            {/* <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md text-center"> */}
            <div className="grid grid-cols-4 gap-1 p-1 w-full">
              <img
                src={`${process.env.PUBLIC_URL}/Sexy.jpg`}
                className=" text-white rounded-xl w-full"
                onClick={() => onLaunchCasino("ae_00002")}
              />
              <img
                src={`${process.env.PUBLIC_URL}/SA.jpg`}
                className="text-white rounded-xl w-full "
                onClick={() => onLaunchCasino("sa_00027")}
              />
              <img
                src={`${process.env.PUBLIC_URL}/Evo.jpg`}
                className="text-white rounded-xl w-full"
                onClick={() => onLaunchCasino("evo_00193")}
              />
              <div
                className="relative w-full"
                onClick={() => onLaunchCasino("pp_00625")}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/PP.jpg`}
                  className="text-white rounded-xl w-full"
                  alt="Promotion"
                />
                {/* <p className="absolute inset-0 flex items-center justify-center text-red-500 text-l font-bold bg-white opacity-50">
                  coming soon
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CasinoBanner;
