import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useClipboard } from "use-clipboard-copy"; // Import here
import "./styles.css";

function Payment() {
  const { id } = useParams();
  const [depositRequest, setDepositRequest] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [webBank, setWebBank] = useState(null);
  const [depositStatus, setDepositStatus] = useState("PENDING");
  const navigate = useNavigate();

  // Wrap fetch functions with useCallback to memoize them
  const fetchDepositRequest = useCallback(async () => {
    try {
      const depositResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/depositrequest/${id}`
      );
      setDepositRequest(depositResponse.data);

      const userResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/${depositResponse.data.userId}`
      );
      setUser(userResponse.data);
    } catch (err) {
      console.error("Error fetching deposit request:", err);
    }
  }, [id]);

  const fetchDepositRequestloop = useCallback(async () => {
    try {
      const depositResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/depositrequest/${id}`
      );
      console.log("Deposit status response:", depositResponse.data);
      setDepositStatus(depositResponse.data.status);
    } catch (err) {
      console.error("Error fetching deposit status:", err);
    }
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchDepositRequest();
        await fetchWebBank();
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(() => {
      fetchDepositRequestloop();
    }, 10000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchDepositRequest, fetchDepositRequestloop]);

  useEffect(() => {
    console.log("Deposit Status:", depositStatus);
    // If depositStatus changes to "UPDATE BY SMS", navigate to the game page
    if (depositStatus === "SUCCESS") {
      navigate("/game");
    }
  }, [depositStatus, navigate]);

  const fetchWebBank = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/webbank`,
        { withCredentials: true }
      );
      console.log("Web Bank fetched:", response.data[0]);
      setWebBank(response.data[0]);
    } catch (error) {
      console.error("Error fetching web bank:", error);
    }
  };

  const handleCancelDeposit = async () => {
    console.log("Attempting to cancel deposit request with ID:", id);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/canceldeposit`,
        { depositRequestId: id },
        { withCredentials: true }
      );
      console.log("Cancel deposit response:", response.data);
      navigate("/game");
    } catch (err) {
      console.error(
        "Error canceling deposit request:",
        err.response ? err.response.data : err.message
      );
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!depositRequest || !user) return <p>No data available</p>;

  return (
    <div className="h-screen flex justify-center items-center relative">
      {/* Background Blur */}
      <div className="absolute inset-0 blur-xl z-0 bg-login"></div>

      {/* Content without Blur */}
      <div className="h-full w-full md:w-[550px] flex flex-col z-10 bg-login">
        <header className="bg-pink-700 text-white py-4 shadow-lg">
          <div className="container mx-auto px-4 flex justify-between items-center">
            <h1 className="text-xl font-bold">Realslot456</h1>
            <div className="flex items-center">
              <button className="bg-green-500 hover:bg-green-400 text-white py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded text-sm">
                เครดิต : {user?.wallet?.balance?.$numberDecimal || 0} บาท
              </button>
            </div>
          </div>
        </header>

        <div className="flex items-center justify-center w-full flex-grow">
          <div className="bg-white shadow-lg w-full text-center mx-2 rounded-xl">
            <div className="flex items-center justify-center border-4mb-10">
              <div className="grid grid-cols-1 p-4 w-full gap-4">
                <h1 className="text-black text-xl pb-4 font-bold">
                  แจ้งเติมเครดิต
                </h1>
                <AmountDetails amount={depositRequest?.amount} />
                <AccountDetails
                  title="โอนจากบัญชี"
                  accountNumber={user.bank?.accountNumber}
                  accountName={user.bank?.accountName}
                  bankCode={user.bank?.bankName}
                  bgColor="yellow"
                />
                <ArrowDownIcon className="self-center p-0" />
                {/* Centers the icon between components */}
                <AccountDetails
                  title="ไปยังบัญชี"
                  accountNumber={webBank?.accountNumber}
                  accountName={webBank?.accountName}
                  bankCode={webBank?.bankName}
                  bgColor="yellow"
                  webbank="true"
                />
                <p>ระบบจะพากลับไปหน้าเกม หากทำการเติมเงินเรียบร้อยแล้ว</p>

                {/* <SummitButton onSummit={handleCancelDeposit} /> */}
                {/* <CancelButton onCancel={handleCancelDeposit} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const AccountDetails = ({
  title,
  accountNumber,
  accountName,
  bankCode,
  bgColor = "gray",
  webbank,
}) => {
  const thaiBanks = [
    { name: "ธนาคารกรุงเทพ", code: "BBL", logo: "/logos/th/bbl.svg" },
    { name: "ธนาคารกสิกรไทย", code: "KBANK", logo: "/logos/th/kbank.svg" },
    { name: "ธนาคารกรุงไทย", code: "KTB", logo: "/logos/th/ktb.svg" },
    { name: "ธนาคารเจพีมอร์แกน เชส", code: "JPMC", logo: "/logos/th/jpm.svg" },
    {
      name: "ธนาคารทหารไทยธนชาต (TTB)",
      code: "TTB",
      logo: "/logos/th/ttb.svg",
    },
    { name: "ธนาคารไทยพาณิชย์", code: "SCB", logo: "/logos/th/scb.svg" },
    { name: "ธนาคารซิตี้แบงก์", code: "CITI", logo: "/logos/th/citi.svg" },
    { name: "ธนาคารทิสโก้", code: "TISCO", logo: "/logos/th/tisco.svg" },
    { name: "ธนาคารยูโอบี", code: "UOBT", logo: "/logos/th/uob.svg" },
    { name: "ธนาคารกรุงศรีอยุธยา", code: "BAY", logo: "/logos/th/bay.svg" },
    { name: "ธนาคารออมสิน", code: "GSB", logo: "/logos/th/gsb.svg" },
    { name: "ธนาคารเกียรตินาคินภัทร", code: "KKP", logo: "/logos/th/kk.svg" },
    { name: "ธนาคารซีไอเอ็มบี ไทย", code: "CIMBT", logo: "/logos/th/cimb.svg" },
    { name: "ธนาคารไอซีบีซี (ไทย)", code: "ICBCT", logo: "/logos/th/icbc.svg" },
    {
      name: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
      code: "LHFG",
      logo: "/logos/th/lhb.svg",
    },
    { name: "ธนาคารซูมิโตโม มิตซุย", code: "SMTB", logo: "/logos/th/smbc.svg" },
  ];

  const bank = thaiBanks.find(
    (item) => item.code.toLocaleLowerCase() === bankCode.toLocaleLowerCase()
  );

  const clipboard = useClipboard(); // Hook inside the functional component
  const [copiedBank, setCopiedBank] = useState(false);

  const handleCopyBank = () => {
    clipboard.copy(accountNumber);
    if (clipboard.success) {
      setCopiedBank(true);
      setTimeout(() => setCopiedBank(false), 2000); // Reset after 2 seconds
    } else {
      //alert("Failed to copy amount.");
    }
  };

  return (
    // <div className={`py-4 px-8 mb-4 border-b-2 rounded-xl bg-${bgColor}-100`}>
    //   <h2 className="text-xl font-bold">{title}</h2>
    //   <p className="text-lg text-gray-700">
    //     <strong>บัญชี : </strong>
    //     {accountName} ({accountNumber})
    //   </p>
    //   <p className="text-lg text-gray-700">
    //     <strong>ธนาคาร : </strong>
    //     {bank?.name || "Unknown Bank"}
    //   </p>
    //   <button
    //     className="mt-3 bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 rounded  w-full"
    //     onClick={handleCopyBank}
    //   >
    //     {copiedBank ? "Copied!" : "คัดลอกเลขบัญชี"}
    //   </button>
    //   <img src={bank?.logo} alt={bank?.name} className="w-24 mt-2" />
    // </div>
    <>
      <div className="flex bg-gray-200 rounded-xl">
        <div className="p-4">
          {bankCode ? (
            <img
              className="m-auto w-20 h-20 fill-gray-700 ml-4"
              src={`${process.env.PUBLIC_URL}${bank.logo}`}
              alt={`${bank.name} logo`}
            />
          ) : (
            <span>Logo not available</span>
          )}
        </div>
        <div className="basis-3/4 flex flex-col justify-center pl-5 mx-auto h-full text-left ml-5">
          <span
            className="rounded-full p-1 text-black"
            // style={{ backgroundColor: bgColor }}
          >
            {title}
          </span>
          <span className="text-black text-xl">{accountNumber || "N/A"}</span>
          <p className="text-gray-700">{accountName || "N/A"}</p>
          <p className="text-gray-700">{bank.name || "N/A"}</p>
          {webbank ? (
            <button
              className="mt-3 bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 rounded  w-full"
              onClick={handleCopyBank}
            >
              {copiedBank ? "Copied!" : "คัดลอกเลขบัญชี"}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const ArrowDownIcon = () => (
  <div className="item-center flex justify-center ">
    <svg
      className="w-16"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 16 16"
      viewBox="0 0 16 16"
      id="down-arrow"
    >
      <polyline
        id="line"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        points="10.5 .5 10.5 8.5 14.5 5.5 14.5 10.5 8 15.5 1.5 10.5 1.5 5.5 5.5 8.5 5.5 .5 8.5 .5"
        display="none"
      ></polyline>
      <path
        id="line_expanded"
        d="M8,16c-0.1,0-0.2,0-0.3-0.1l-6.5-5C1.1,10.8,1,10.7,1,10.5v-5c0-0.2,0.1-0.4,0.3-0.4C1.4,5,1.6,5,1.8,5.1L5,7.5v-7
			C5,0.2,5.2,0,5.5,0h3C8.8,0,9,0.2,9,0.5S8.8,1,8.5,1H6v7.5c0,0.2-0.1,0.4-0.3,0.4C5.6,9,5.4,9,5.2,8.9L2,6.5v3.8l6,4.6l6-4.6V6.5
			l-3.2,2.4c-0.1,0.1-0.4,0.1-0.5,0C10.1,8.9,10,8.7,10,8.5v-8C10,0.2,10.2,0,10.5,0S11,0.2,11,0.5v7l3.2-2.4c0.2-0.1,0.4-0.1,0.5,0
			C14.9,5.1,15,5.3,15,5.5v5c0,0.2-0.1,0.3-0.2,0.4l-6.5,5C8.2,16,8.1,16,8,16z"
        display="none"
      ></path>
      <g id="filled_line">
        <polygon
          fill="#f9c00c"
          points="10.5 8.5 10.5 .5 5.5 .5 5.5 8.5 1.5 5.5 1.5 8.6 9.2 14.6 14.5 10.5 14.5 5.5"
        ></polygon>
        <path
          fill="#3e3643"
          d="M8,16c-0.1,0-0.2,0-0.3-0.1l-6.5-5C1.1,10.8,1,10.7,1,10.5v-5c0-0.2,0.1-0.4,0.3-0.4C1.4,5,1.6,5,1.8,5.1
				L5,7.5v-7C5,0.2,5.2,0,5.5,0h3C8.8,0,9,0.2,9,0.5S8.8,1,8.5,1H6v7.5c0,0.2-0.1,0.4-0.3,0.4C5.6,9,5.4,9,5.2,8.9L2,6.5v3.8l6,4.6
				l6-4.6V6.5l-3.2,2.4c-0.1,0.1-0.4,0.1-0.5,0C10.1,8.9,10,8.7,10,8.5v-8C10,0.2,10.2,0,10.5,0S11,0.2,11,0.5v7l3.2-2.4
				c0.2-0.1,0.4-0.1,0.5,0C14.9,5.1,15,5.3,15,5.5v5c0,0.2-0.1,0.3-0.2,0.4l-6.5,5C8.2,16,8.1,16,8,16z"
        ></path>
      </g>
    </svg>
  </div>
);

const AmountDetails = ({ amount }) => {
  const clipboard = useClipboard(); // Hook inside the functional component
  const [copied, setCopied] = useState(false);

  const handleCopyAmount = () => {
    clipboard.copy(amount?.$numberDecimal ? `${amount.$numberDecimal}` : "N/A");
    if (clipboard.success) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } else {
      //alert("Failed to copy amount.");
    }
  };

  return (
    <div className="flex items-center justify-center rounded-xl bg-green-700">
      <div className="p-5 rounded-lg shadow-lg w-full flex flex-col justify-center ">
        <span className="text-white">กรุณาโอนเงินจำนวน</span>
        <h1
          className="text-2xl text-white font-extrabold"
          ref={clipboard.target}
        >
          {amount?.$numberDecimal ? `${amount.$numberDecimal}` : "N/A"}
        </h1>
        <button
          className="mt-3 bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 rounded"
          onClick={handleCopyAmount}
        >
          {copied ? "Copied!" : "คัดลอกจำนวนเงิน"}
        </button>
      </div>
    </div>
  );
};

const SummitButton = ({ onSummit }) => (
  <div className="py-4 px-8">
    <button
      onClick={onSummit}
      className="w-full py-3 mt-5 text-white bg-red-500 rounded-xl shadow-md hover:bg-red-400"
    >
      ยืนยันการเติมเครดิต
    </button>
  </div>
);

export default Payment;
