import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles.css"; // Ensure you have your custom styles here
import ppPlay from "../ppplay.png";

const Slots = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [ppPlay, ppPlay, ppPlay];
  const itemsPerSlide = 3;

  const [playerId, setPlayerId] = useState(null);
  const [user, setUser] = useState(null);
  const [allgames, setAllGames] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(
        (prevSlide) =>
          (prevSlide + 1) % Math.ceil(slides.length / itemsPerSlide)
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/api/profile",
        {
          withCredentials: true,
        }
      );
      setPlayerId(response.data._id);
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchAllGames = async () => {
    try {
      let config = {
        method: "get",
        url: "https://s.ss365api.com/gs/api/platform/games/list?casinoId=14000039&includeDisabled&bonusProgress=true",
        headers: {
          "hg-agent-user": "realslot456",
          "hg-agent-password": "PvuPhGIetHKZNsVaM1m8",
        },
      };

      const response = await axios.request(config);
      setAllGames(response.data.response);
    } catch (error) {
      console.error("Error fetching all games:", error);
    }
  };

  useEffect(() => {
    setLoading(true); // Start loading
    fetchUserDetails();
    fetchAllGames();

    const handlePopState = () => {
      fetchUserDetails();
    };

    window.addEventListener("popstate", handlePopState);

    // Set timeout to hide the loading spinner after 5 seconds
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Cleanup the event listener and timeout
    return () => {
      window.removeEventListener("popstate", handlePopState);
      clearTimeout(loadingTimeout);
    };
  }, []);

  const getVisibleSlides = () => {
    const start = currentSlide * itemsPerSlide;
    return slides.slice(start, start + itemsPerSlide);
  };

  const handleGameClick = async (vendor) => {
    if (!playerId) {
      console.error("Player ID is not available");
      return;
    }

    // Game handling logic
  };

  return (
    <div className="min-h-screen bg-login flex flex-col">
      <header className="bg-pink-700 text-white py-4 shadow-lg">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <h1 className="text-xl font-bold">Realslot456</h1>
          <button
            // onClick={() => handleLogout()}
            className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
          >
            Logout
          </button>
        </div>
      </header>

      <div className="flex-grow flex items-center justify-center opacity-90">
        {loading ? ( // Display the loading spinner if loading is true
          <div class="relative flex justify-center items-center">
            <div class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-purple-500"></div>
            <img
              src="https://www.svgrepo.com/show/509001/avatar-thinking-9.svg"
              class="rounded-full h-28 w-28"
            ></img>
          </div>
        ) : (
          <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md text-center">
            <div className="grid grid-cols-2 gap-2 mb-10">
              <h1 className="font-bold col-span-2 pb-4">
                <span className="text-s">
                  {user?.displayName || user?.username || ""}
                </span>
                <br />
                <span className="text-xl">
                  เครดิต : {user?.wallet?.balance?.$numberDecimal || 0} บาท
                </span>
              </h1>
              <button
                // onClick={() => handleNavigation("/deposit")}
                className="bg-green-500 hover:bg-green-400 text-white font-bold py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded"
              >
                ฝาก
              </button>
              <button
                // onClick={() => handleNavigation("/withdraw")}
                className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
              >
                ถอน
              </button>
            </div>

            <div className="carousel rounded-box">
              <div
                className="carousel-inner flex transition-transform duration-500 ease-in-out"
                style={{
                  transform: `translateX(-${
                    currentSlide * (100 / (slides.length / itemsPerSlide))
                  }%)`,
                }}
              >
                {getVisibleSlides().map((slide, index) => (
                  <div key={index} className="carousel-item relative w-full">
                    <img
                      src={slide}
                      alt={`Slide ${index + 1}`}
                      className="w-full"
                    />
                  </div>
                ))}
              </div>

              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <button
                  onClick={() =>
                    setCurrentSlide(
                      (currentSlide -
                        1 +
                        Math.ceil(slides.length / itemsPerSlide)) %
                        Math.ceil(slides.length / itemsPerSlide)
                    )
                  }
                  className="btn btn-circle"
                >
                  ❮
                </button>
                <button
                  onClick={() =>
                    setCurrentSlide(
                      (currentSlide + 1) %
                        Math.ceil(slides.length / itemsPerSlide)
                    )
                  }
                  className="btn btn-circle"
                >
                  ❯
                </button>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-8">
              {Array.isArray(allgames) &&
                allgames.map((game) => (
                  <img
                    key={game.id}
                    src={ppPlay}
                    alt={game.name}
                    onClick={() => handleGameClick(game.id)}
                    className="cursor-pointer"
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Slots;
