import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css";

function Paymentstatus() {
  const [user, setUser] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/profile",
          {
            withCredentials: true,
          }
        );
        console.log("User details fetched:", response.data);
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    const fetchTransactions = async () => {
      try {
        const depositResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/depositandwithdrawreq`,
          { withCredentials: true }
        );

        // const withdrawResponse = await axios.get(
        //   `${process.env.REACT_APP_API_URL}/api/withdrawrequests`,
        //   { withCredentials: true }
        // );
        // console.log("withdrawResponse", withdrawResponse.data);

        const allTransactions = [
          ...depositResponse.data.map((transaction) => ({
            ...transaction,
          })),
        ];

        const sortedTransactions = allTransactions.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setTransactions(sortedTransactions);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchUserDetails();
    fetchTransactions();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col bg-login">
      <header className="bg-gradient-to-r from-purple-500 to-pink-500 text-white py-4 shadow-lg">
        <div className="container mx-auto px-4">
          <h1 className="text-2xl font-semibold">Realslot456</h1>
        </div>
      </header>

      <div className="flex items-center justify-center mt-10">
        <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-lg">
          <h2 className="text-xl font-bold mb-6 text-gray-800">
            Transaction History
          </h2>
          {transactions.length > 0 ? (
            transactions.map((transaction) => (
              <div
                key={transaction._id}
                className={`p-4 mb-4 border rounded-lg ${
                  transaction.status === "PENDING"
                    ? "bg-yellow-100 border-yellow-400"
                    : transaction.status === "APPROVED" ||
                      transaction.status === "SUCCESS"
                    ? "bg-green-100 border-green-400"
                    : "bg-red-100 border-red-400"
                }`}
              >
                <p className="text-lg font-medium text-gray-700">
                  {transaction.type}:{" "}
                  {transaction.amount?.$numberDecimal
                    ? `${transaction.amount.$numberDecimal} THB`
                    : "N/A"}
                </p>
                <p className="text-sm text-gray-500">
                  Status: {transaction.status}
                </p>
                <p className="text-sm text-gray-500">
                  Date:{" "}
                  {new Date(transaction.createdAt).toLocaleString("en-GB", {
                    timeZone: "Asia/Bangkok",
                  })}
                </p>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No transactions found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Paymentstatus;
