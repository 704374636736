// src/components/Game/GameSection.js
import React from "react";
import axios from "axios";

const Huay = ({ onLaunchHuay }) => {
  return (
    <>
      <div className="flex items-center justify-center w-full mt-1">
        <div className="bg-pink-700 shadow-xl w-full text-center mx-2  rounded-t-xl">
          <div className="text-sm flex-grow flex opacity-90 border-4mb-10 p-1 text-white justify-center">
            หวย
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full ">
        <div className="bg-white shadow-lg w-full text-center mx-2 rounded-b-xl ">
          <div className="flex-grow flex items-center justify-center opacity-90 border-4mb-10">
            {/* <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md text-center"> */}
            <div className="grid grid-cols-1 md:grid-cols-1 ">
              <div className="flex-row">
                <img
                  src={`${process.env.PUBLIC_URL}/banner/huaydragon.jpg`}
                  className="text-white rounded-lg w-full p-1"
                  onClick={() => onLaunchHuay()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Huay;
