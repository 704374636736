// src/components/Game/GameSection.js
import React from "react";
import GameGrid from "./GameGrid";

const GameSection = ({ title, games, onLaunchGame }) => {
  return (
    <div>
      {/* Section Header */}
      <div className="flex items-center justify-center w-full mt-1">
        <div className="bg-pink-700 shadow-xl w-full text-center mx-2  rounded-t-xl">
          <div className="text-sm flex-grow flex opacity-90 border-4mb-10 p-1 text-white justify-center">
            {title}
          </div>
        </div>
      </div>

      {/* Section Content */}
      <div className="flex items-center justify-center w-full">
        <div className="bg-white shadow-lg w-full text-center mx-2 rounded-b-xl">
          <div className="flex-grow flex items-center justify-center opacity-90 border-4mb-10">
            <GameGrid games={games} onLaunchGame={onLaunchGame} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameSection;
