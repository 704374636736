// src/components/Game/GameCard.js
import React, { useMemo } from "react";

const GameCard = ({ game, onLaunchGame }) => {
  // Determine the image path based on the game's brand
  const getImagePath = () => {
    return `${process.env.PUBLIC_URL}/pgsoftgames/${game.alias.toLowerCase()}${
      game.brand === "pgsoft" ? "_icon.webp" : ".png"
    }`;
  };

  // Use useMemo to randomly decide whether to add the bounce effect and the 🎰 icon
  const shouldBounce = useMemo(() => {
    // Set a probability for adding bounce effect and 🎰 icon, e.g., 30% chance
    return Math.random() < 0.3;
  }, []);

  return (
    <div className="flex flex-col items-center m-2">
      {/* Wrapper with conditional bounce effect */}
      <div>
        {/* Game Image */}
        <img
          src={getImagePath()}
          onError={(e) => {
            e.target.onerror = null; // Prevent looping
            e.target.src = `${
              process.env.PUBLIC_URL
            }/pgsoftgames/${game.alias.toLowerCase()}.png`;
          }}
          alt={game.name}
          className="text-white rounded-lg mt-2 cursor-pointer z-0"
          onClick={() => onLaunchGame(game.id)}
        />
      </div>
      <p className="text-xs mt-1">{game.name}</p>
    </div>
  );
};

export default GameCard;
