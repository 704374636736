import React, { useState, useEffect } from "react";
import "./styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Deposit = () => {
  const defaultPromotionId = "2";
  const [selectedPromotion, setSelectedPromotion] =
    useState(defaultPromotionId);
  const [user, setUser] = useState(null);
  const [depositAmount, setDepositAmount] = useState(0);
  const navigate = useNavigate();
  const amounts = [100, 200, 300, 400, 500, 1000, 2000, 3000, 5000];

  const promotions = [
    { name: "ไม่รับโปรโมชั่น", id: "1", msg: "" },
    {
      name: "รับโปรเติม 200 บาทรับ 50 บาท",
      id: "2",
      msg: "ทำเทิร์นโอเวอร์ 5 เท่าของยอดฝาก + โบนัส",
    },
  ];

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/profile",
          { withCredentials: true }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  // Automatically set deposit amount to 200 if promotion 2 is selected
  useEffect(() => {
    if (selectedPromotion === "2") {
      setDepositAmount(200);
    }
  }, [selectedPromotion]);

  const handleAmountClick = (amount) => {
    setDepositAmount(amount);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (depositAmount < 100) {
      alert("เติมเงินขั้นต่ำ 100 บาท");
      return;
    }
    if (selectedPromotion === "2" && depositAmount > 1000) {
      alert("รับโปรโมชั่นได้สูงสุด 1000 บาท");
      return;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/requestdeposit",
        { amount: depositAmount, promotion: selectedPromotion },
        { withCredentials: true }
      );

      const { id } = response.data;
      navigate(`/payment/${id}`);
    } catch (error) {
      console.error("Error submitting deposit request:", error);
      alert("Failed to submit deposit request. Please try again.");
    }
  };

  const selectedPromotionMessage = promotions.find(
    (promo) => promo.id === selectedPromotion
  )?.msg;

  return (
    <div className="h-screen flex justify-center items-center relative">
      <div className="absolute inset-0 blur-xl z-0 bg-login"></div>
      <div className="h-full w-full md:w-[550px] flex flex-col z-10 bg-login">
        <header className="bg-pink-700 text-white py-4 shadow-lg">
          <div className="container mx-auto px-4 flex justify-between items-center">
            <h1 className="text-xl font-bold">Realslot456</h1>
            <div className="flex items-center">
              <button className="bg-green-500 hover:bg-green-400 text-white py-2 px-4 border-b-4 border-green-700 hover:border-green-500 rounded text-sm">
                เครดิต : {user?.wallet?.balance?.$numberDecimal || 0} บาท
              </button>
            </div>
          </div>
        </header>
        <div className="flex items-center justify-center w-full flex-grow">
          <div className="bg-white shadow-lg w-full text-center mx-2 rounded-xl">
            <div className="flex items-center justify-center opacity-90 border-4mb-10">
              <div className="grid grid-cols-1 md:grid-cols-1 p-4 w-full">
                <h1 className="text-2xl text-black font-bold">ฝากเงิน</h1>
                <span className="text-black">ระบุจำนวนเงินที่ต้องการเติม</span>
                <form onSubmit={handleSubmit} className="w-full">
                  <input
                    type="number"
                    className="bg-gray-300 w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-pink-600 mt-5 text-black"
                    placeholder="100.00"
                    value={depositAmount}
                    onChange={(e) => setDepositAmount(Number(e.target.value))}
                  />
                  <div className="grid grid-cols-3 gap-2 mt-4">
                    {amounts.map((amount) => (
                      <div
                        key={amount}
                        className="bg-pink-700 text-white py-2 rounded-lg hover:bg-pink-500 focus:outline-none focus:ring-2 focus:bg-pink-500"
                        onClick={() => handleAmountClick(amount)}
                      >
                        {amount}
                      </div>
                    ))}
                  </div>
                  {user && !user.promotion && (
                    <>
                      <select
                        className="w-full border border-gray-300 rounded-lg p-2 mt-4 focus:outline-none focus:ring-2 focus:ring-pink-600"
                        value={selectedPromotion}
                        onChange={(e) => setSelectedPromotion(e.target.value)}
                      >
                        <option value="" disabled={!selectedPromotion}>
                          เลือกโปรโมชั่น
                        </option>
                        {promotions.map((promotion) => (
                          <option key={promotion.id} value={promotion.id}>
                            {promotion.name}
                          </option>
                        ))}
                      </select>
                      {selectedPromotionMessage && (
                        <div className="mt-2 text-sm text-gray-600">
                          {selectedPromotionMessage}
                        </div>
                      )}
                    </>
                  )}
                  <button
                    type="submit"
                    className="w-full bg-pink-700 text-white mt-6 mb-6 py-2 rounded-lg hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-pink-700"
                  >
                    เติมเงิน
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
