// src/components/Game/GameHeader.js
import React from "react";
import UserBalance from "./UserBalance";
import line from "../../line.svg";

const GameHeader = ({ user }) => {
  return (
    <header className="bg-pink-700 text-white py-2 shadow-lg">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <h1 className="text-l font-bold">Realslot456</h1>
        <div className="flex">
          <a href="https://lin.ee/tHt5a3b">
            <img src={line} alt="logo" className="w-10 h-10 mx-2" />
          </a>
          <UserBalance balance={user?.wallet?.balance?.$numberDecimal} />
        </div>
      </div>
    </header>
  );
};

export default GameHeader;
