// src/components/Game/GameSection.js
import React from "react";
import Marquee from "react-fast-marquee";

const gameIconMarquee = [
  {
    name: "Horus Eye",
    brand: "gwa",
    alias: "HorusEye",
    id: 247,
    createdAt: 1704369714,
    rtp: [97, 777],
    lines: 10,
    bonusProgress: null,
  },
  {
    name: "Chase for Glory",
    brand: "pragmatic",
    alias: "ChaseforGlory",
    id: 249,
    createdAt: 1704816857,
    lines: 30,
    symbol: "vswayscfglory",
    rtp: [97],
    tags: ["AllGames"],
    bonusProgress: 0.0026,
  },
  {
    name: "Lucky Clover Lady",
    brand: "pgsoft",
    alias: "LuckyCloverLady",
    id: 250,
    createdAt: 1704946885,
    rtp: [97, 777],
    lines: 20,
    tags: ["AllGames"],
    bonusProgress: null,
  },
  {
    name: "Ultra Burn",
    brand: "pragmatic",
    alias: "UltraBurn",
    id: 251,
    createdAt: 1705315123,
    symbol: "vs5ultrab",
    rtp: [97],
    lines: 5,
    tags: ["AllGames"],
    bonusProgress: null,
  },
  {
    name: "Ultra Burn Hold n Spin",
    brand: "pragmatic",
    alias: "UltraBurnHoldnSpin",
    id: 252,
    createdAt: 1705315201,
    symbol: "vs5ultra",
    rtp: [97],
    lines: 5,
    tags: ["AllGames"],
    bonusProgress: 0,
  },
  {
    name: "Ultra Burn",
    brand: "pragmatic",
    alias: "UltraBurn",
    id: 251,
    createdAt: 1705315123,
    symbol: "vs5ultrab",
    rtp: [97],
    lines: 5,
    tags: ["AllGames"],
    bonusProgress: null,
  },
  {
    name: "Ultra Burn Hold n Spin",
    brand: "pragmatic",
    alias: "UltraBurnHoldnSpin",
    id: 252,
    createdAt: 1705315201,
    symbol: "vs5ultra",
    rtp: [97],
    lines: 5,
    tags: ["AllGames"],
    bonusProgress: 0,
  },
];
const getImagePath = (game) => {
  return `${process.env.PUBLIC_URL}/pgsoftgames/${game.alias.toLowerCase()}${
    game.brand === "pgsoft" ? "_icon.webp" : ".png"
  }`;
};

const GameIconMarquee = () => {
  return (
    <>
      <div className="flex items-center justify-center w-full mt-1">
        <div className="bg-pink-700 shadow-xl w-full text-center mx-2  rounded-t-xl">
          <div className="text-sm flex-grow flex opacity-90 border-4mb-10 p-1 text-white justify-center">
            เกมส์ยอดนิยม
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full ">
        <div className="bg-gray-200 opacity-90 shadow-lg w-full text-center mx-2 rounded-b-xl ">
          <div className="flex-grow flex items-center justify-center border-4mb-10">
            {/* <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-md text-center"> */}
            <div className="flex overflow-hidden border-pink-700 border-1 rounded-xl">
              <div className="flex w-full">
                <Marquee speed="20">
                  {gameIconMarquee.map((game) => (
                    <img
                      key={game.id}
                      src={getImagePath(game)}
                      onError={(e) => {
                        e.target.onerror = null; // Prevent looping
                        e.target.src = `${
                          process.env.PUBLIC_URL
                        }/pgsoftgames/${game.alias.toLowerCase()}.png`;
                      }}
                      alt={game.name}
                      className="text-white rounded-lg mt-2 cursor-pointer z-0 w-24 pb-2 px-1 shadow "
                      //onClick={() => onLaunchGame(game.id)}
                    />
                  ))}
                </Marquee>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameIconMarquee;
