// src/components/Game/GameFooter.js
import React from "react";
import withdraw from "../../withdraw.svg";
import deposit from "../../deposit.svg";
import promotion from "../../promotion.svg";

import { useNavigate } from "react-router-dom";

const GameFooter = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <div className="fixed bottom-0 bg-pink-700 text-white flex justify-around items-center shadow-md z-10 border-pink-800 border-t-2 md:w-[550px] w-full p-1">
      <div className="text-center cursor-pointer">
        <div
          className="flex   justify-center"
          onClick={() => handleNavigation("/deposit")}
        >
          <img class="w-5 flex" src={deposit} />
        </div>
        <div className="text-xs">ฝากเงิน</div>
      </div>
      <div
        className="text-center cursor-pointer"
        onClick={() => handleNavigation("/")}
      >
        <div className="flex  justify-center">
          <img class="w-5 flex" src={promotion} />
        </div>
        <div className="text-xs flex">หน้าหลัก</div>
      </div>
      <div
        className="text-center cursor-pointer"
        onClick={() => handleNavigation("/withdraw")}
      >
        <div className="flex   justify-center">
          <img class="w-5 flex" src={withdraw} />
        </div>
        <div className="text-xs">ถอนเงิน</div>
      </div>
    </div>
  );
};

export default GameFooter;
