import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Game from "./components/Game/Game";
import Register from "./components/Register";
import ProtectedRoute from "./components/ProtectedRoute";
import Deposit from "./components/Deposit";
import Withdraw from "./components/Withdraw";
import Payment from "./components/Payment";
import Paymentstatus from "./components/Paymentstatus";
import Slots from "./components/Slots";
import NotFoundPage from "./components/NotFoundPage";
import LoginBlank from "./components/LoginBlank";
import Admin from "./components/Admin";
import User from "./components/User";
import Dashboard from "./components/Dashboard";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />

        {/* Protected Routes for members, with admin access allowed */}
        <Route element={<ProtectedRoute requiredRole="member" />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/game" element={<Game />} />
          <Route path="/deposit" element={<Deposit />} />

          <Route path="/payment/:id" element={<Payment />} />
          <Route path="/paymentstatus" element={<Paymentstatus />} />
        </Route>

        {/* Protected Routes for admin only */}
        <Route element={<ProtectedRoute requiredRole="admin" />}>
          <Route path="/admin" element={<Admin />} />
          <Route path="/user" element={<User />} />
        </Route>
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<LoginBlank />} />
        <Route path="/register" element={<Register />} />
        <Route path="/slots" element={<Slots />} />
        <Route path="/not-authorized" element={<div>Not Authorized</div>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
