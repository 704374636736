import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import videoBackground from "../vdo.mp4";
import "./styles.css";
import hero from "../hero.png";

const LoginRegister = () => {
  const navigate = useNavigate();
  const [isRegister, setIsRegister] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    bankName: "",
    accountNumber: "",
    accountName: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const videoRef = useRef(null);

  // Handle form field changes in a single handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    // Lazy load the video
    setIsVideoLoaded(true);

    const enableAutoplayOnUserGesture = () => {
      if (videoRef.current) {
        videoRef.current.muted = true;
        videoRef.current.play().catch((error) => {
          console.log("Autoplay prevented. Playing on user gesture:", error);
        });
      }
    };

    // Attach a touchstart event for iOS devices to trigger play
    document.addEventListener("touchstart", enableAutoplayOnUserGesture, {
      once: true,
    });

    // Clean up the event listener after first interaction
    return () => {
      document.removeEventListener("touchstart", enableAutoplayOnUserGesture);
    };
  }, []);

  useEffect(() => {
    // Debounced window resize handling
    const debounce = (func, delay) => {
      let timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(func, delay);
      };
    };

    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    const handleResize = debounce(setVh, 100);
    window.addEventListener("resize", handleResize);

    setVh(); // Initial call

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            username: formData.username,
            password: formData.password,
          }),
        }
      );

      if (response.ok) {
        navigate("/game");
      } else {
        const data = await response.json();
        setErrorMessage(data.message || "Login failed");
      }
    } catch (error) {
      setErrorMessage("Login failed");
      console.error("Login error:", error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: formData.username,
            password: formData.password,
            bankName: formData.bankName,
            accountNumber: formData.accountNumber,
            accountName: formData.accountName,
          }),
        }
      );

      if (response.ok) {
        navigate("/game");
      } else {
        const data = await response.json();
        setErrorMessage(data.message || "Registration failed");
      }
    } catch (error) {
      setErrorMessage("Registration failed");
      console.error("Registration error:", error);
    }
  };

  const handleToggle = () => {
    setIsRegister(!isRegister);
    setErrorMessage("");
  };

  return (
    <div className="min-h-screen flex flex-col relative">
      {/* Lazy-loaded Video Background */}
      {isVideoLoaded && (
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src={videoBackground} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Content */}
      <header className="relative z-10 bg-pink-700 text-white py-4 shadow-lg">
        <div className="container mx-auto px-4">
          <h1 className="text-xl font-bold text-center">REALSLOT456</h1>
        </div>
      </header>

      {/* Centered Content */}
      <div className="flex-center relative z-10">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-sm bg-with-opacity">
          {isRegister ? (
            <>
              <h1 className="text-2xl font-bold mb-6 text-center">
                สมัครสมาชิก
              </h1>
              <form onSubmit={handleRegister}>
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={formData.username}
                  onChange={handleChange}
                  className="mb-4 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  className="mb-4 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                <input
                  type="text"
                  name="bankName"
                  placeholder="Bank Name"
                  value={formData.bankName}
                  onChange={handleChange}
                  className="mb-4 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                <input
                  type="text"
                  name="accountNumber"
                  placeholder="Account Number"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  className="mb-4 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                <input
                  type="text"
                  name="accountName"
                  placeholder="Account Name"
                  value={formData.accountName}
                  onChange={handleChange}
                  className="mb-6 w-full px-4 py-2 border rounded-lg input-no-opacity"
                  required
                />
                {errorMessage && (
                  <p className="text-red-500 text-center mb-4">
                    {errorMessage}
                  </p>
                )}
                <button
                  type="submit"
                  className="w-full bg-green-500 text-white py-4 px-4 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 input-no-opacity"
                >
                  Register
                </button>
              </form>
              <p className="mt-4 text-center">
                Already have an account?{" "}
                <button
                  onClick={handleToggle}
                  className="text-blue-500 hover:underline focus:outline-none"
                >
                  Login here
                </button>
              </p>
            </>
          ) : (
            <>
              <img src={hero} alt="hero" className="w-20 mx-auto mb-5" />
              <button
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API_URL + "/api/login")
                }
                className="w-full bg-green-500 text-white py-4 px-4 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                สมัครสมาชิก
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginRegister;
